<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="col-md-12">
                <h2 class="pink p-block">
                    Privacy Policy
                </h2>
            </div>
            <div class="col-md-12 p-block">
                <p>
                    We are committed to protecting your privacy and any personal information you share with us. As part of
                    this effort, we process personal information in accordance with the EU’s General Data Protection
                    Regulation (GDPR) and other data protection regulations applicable to us. That is why we would like to
                    explain in this privacy policy which data of yours will be processed when you visit our website, contact
                    us or make use of our services, for what purpose we process your personal data and what rights you have
                    with regard to the processing of your personal data. We have outlined our privacy policy below. It is
                    recommended that you read this privacy policy carefully.
                </p>
                <p>
                    This privacy policy may be changed from time to time. The latest version of this privacy policy will be
                    placed on our website. In case of significant changes that may have material impact on you, we will
                    strive to inform you directly. The latest version of this privacy policy is dated 01 April 2021.
                </p>

                <h3>WHO ARE WE AND HOW CAN YOU CONTACT US?</h3>
                <p>
                    We offer a digital fantasy chat service for adults designed as a forum for chat with animators, a place
                    to have discussions of a sexual nature, and to share photos and other information. The party responsible
                  for the processing of your personal data (the data controller) is <span class="rtl">{{ companyName }}</span>, which
                    also
                    operates under the trade name of {{ instanceInfo?.name }}, a company
                    having its address at {{ instanceInfo?.companyAddress1 }}, {{ instanceInfo?.companyAddress2 }}.
                </p>
                <p>
                    If you have any questions or wishes in connection with your personal data, you may contact our data
                    protection officer: dpo@{{ instanceInfo?.domain }}.
                </p>

                <h3>TO WHOM DOES THIS PRIVACY POLICY APPLY?</h3>
                <p>
                    This privacy policy applies to persons who visits and/or make use of the website and/or the services.
                    While some functionality of the website can be accessed without registering for an account, such as
                    browsing and learning more about our services, you need an account for additional functionality. This
                    privacy policy also applies to persons contact us, via email, text, and other (electronic messages) or
                    when you interact with our company\'s advertising and applications on third-party websites and services.
                </p>

                <h3>HOW DO WE USE YOUR PERSONAL DATA?</h3>
                <p>
                    Below is an overview of the purposes for which we process your personal data. In this overview, we
                    indicate the personal data we use for a specific purpose, what the legal basis is for processing these
                    data, and how long we store the data. To keep things organized, we have grouped everything by type of
                    data stream.<br>
                </p>
                <h4>Registration</h4>
                <p>In order to use our services, you have to create an account. When you register for such an account, we
                    ask you to provide personal data, including your email address, your gender, date of birth, region, and
                    your chat preferences. We request you to make a username and password for your account as well. </p>
                <p>We process these personal data in order to enable you to access and use the service, including uploading,
                    downloading, collaborating on, and sharing content. We need this personal data for the performance of a
                    contract in connection with the use of service to which you are a party (Article 6.1(b) of the GDPR.
                    Without this personal information, it is not possible to make use of our services. </p>

                <h4>Account</h4>
                <p>After you signed up, you can complete your profile with optional information, such as body type, eye
                    color, hair color, height. You can also upload photos, sexual preferences and any other optional
                    information you provide about yourself. Based on your profile settings, you will receive chat
                    suggestions. You can chat with other users and also set certain visibility preferences. You may also
                    provide information to be published or displayed ("posted") on areas on the website that are visible for
                    or transmitted to other users of the website or third parties (collectively, "user contributions"). We
                    process we process this personal data based on your consent (Article 6.1(a) GDPR). Where it concerns
                    special categories of personal data, we process this personal data based on your explicit consent to the
                    processing of those personal data (Article 9.2(a) GDPR).</p>
                <p>We store your personal data for as long as you have an account and will delete your data within twelve
                    months after you deactivate your account. We have a legal obligation to retain financial records for tax
                    purposes for a period of 7 years. However, we will continue to use your email address to contact you for
                    our services until you have opted out. </p>

                <h4>Contact</h4>
                <p>To provide (international) customer service we may collect your personal data to respond to your
                    questions you submit through email or the contact form on the website, including helping you with any
                    issues which may arise regarding our website and/or services. For this purpose, we may process your
                    account details as well as your purchase history and the nature of the question, complaint or claim. The
                    processing of these personal data is necessary for the purposes of the legitimate interests pursued by
                    us (Article 6.1(f) GDPR), namely being able to serve you efficiently and to optimize our customer
                    service. </p>
                <p>Your personal data is stored for a period of 1 month after responding to your message or resolving your
                    complaint.</p>

                <h4>(Promotional) emails</h4>
                <p>if you have signed up for our emails, we can send promotional (personalized) communications, such as
                    providing you with information about our services, features, newsletters, offers, promotions, contests,
                    surveys and events. We can also send you technical notices, updates, security alerts. The processing of
                    your personal data is necessary for the purposes of the legitimate interests pursued by us (Article
                    6.1(f) GDPR), namely direct marketing and optimize our services. Recipients may at any time unsubscribe
                    (opt-out) for such communications. We retain your email address for as long as you are subscribed and no
                    longer than 1 month after you unsubscribe.</p>

                <h4>Email tracking,</h4>
                <p>We included email tracking and link tracking features in our emails by embedding a small transparent
                    image pixel in the outgoing email. This pixel sends us a notification in the event the email is opened.
                    The processing of these personal data is necessary for the purposes of the legitimate interests pursued
                    by us (Article 6.1(f) GDPR), namely, to analyse the reach and effectiveness of the mails sent by us. The
                    information is retained for two months.</p>

                <h4>Profiling</h4>
                <p>We may also send you personalized advertising mails depending on among other things your place of
                    residence, your account activity, behavior when using the website and service and your preferences. We
                    also use this information to recognize you when you return to the website and to customize the website
                    to your individual interests, for example to provide you with chat suggestions you might be interested
                    in. The processing of your data is only based on your consent (Article 6.1(a) GDPR). The personal data
                    is stored for a period of 6 months.</p>

                <h4>Transactions</h4>
                <p>To use certain parts of our website, you need a paid subscription or a credit bundle. By placing an order
                    through the website, we collect your personal data to handle your orders, such as the date and time of
                    your order, what kind of order you placed, your order number, and your account information. We use this
                    personal data to process and complete transactions, and send you related information, including purchase
                    confirmations and invoices. The processing of your personal data in this context is necessary for the
                    performance of a contract in connection with the use of service to which you are a party (Article 6.1(b)
                    GDPR). Also, we may process your personal data because this is necessary to comply with statutory
                    obligations, such as tax obligations (Article 6.1(c) GDPR). We have a legal obligation to retain
                    financial records for tax purposes for a period of 7 years.</p>
                <p>Payments are made via third party service providers. To make the payment, you are automatically
                    redirected to the secure environment of your chosen payment provider. You will automatically be
                    redirected back to the Website once the payment has been successful. We refer you to the Privacy
                    Statement of these payment providers for more information about this.</p>

                <h4>Analytics</h4>
                <p>We use third-party analytics tools to help measure traffic and usage trends for the website and app.
                    These tools collect information sent by your device or our website, including the web pages you visit,
                    add-ons, and other information that assists us in improving the website and the service. The tools use
                    cookies. We collect and use this analytics information with analytics information from other users so
                    that it cannot reasonably be used to identify any particular individual user. For more information,
                    please read our cookie policy. </p>

                <h4>Use of the website</h4>
                <p>We automatically collect generated data about your use of the website. This information consists of your
                    IP address (an unique number, which makes it possible to recognize your device); account activity
                    (including storage usage, number of log-ins); data displayed or clicked on (including UI elements,
                    links); and other log information (including browser type, IP address, date and time of access, cookie
                    ID, and referrer URL ). We require this information in order for the website to work as optimally as
                    possible (for example, to display content correctly and to keep the website secure), the processing of
                    these personal data is therefore necessary for the purposes of the legitimate interests pursued by us
                    (Article 6.1(f) GDPR) and are stored for twelve months.</p>

                <h4>Remarketing</h4>
                <p>Advertising is only effective if it is relevant to you. We, our ad networks and media agencies may
                    therefore use your personal data to create customer profiles and display personalized advertisements on
                    the website and other websites. This is based on your previous visits to our website and third-party
                    websites. Your surf, search and purchase behaviour will be collected on the basis of cookie techniques,
                    your IP address and the advertisement number that is linked to the device you are surfing with. We will
                    only do this if you have consented to cookies (Article 6(a) GDPR). Please read the Cookie Policy to find
                    out more. The personal data are stored for a period of 12 months. </p>

                <h4>Security</h4>
                <p>We process personal data in order to secure our systems and data, investigate and prevent fraudulent
                    transactions, unauthorized access to the Website, and other illegal activities. Furthermore, we may
                    process personal data in order to comply with statutory obligations and requests of authorities and to
                    avoid and settle claims. For these purposes, we may process all types of personal data as described in
                    this Privacy Policy for these purposes.
                    The processing of these personal data is necessary for the purposes of the legitimate interests pursued
                    by our company (Article 6.1(f) GDPR), namely security and business interests of the company.
                    Furthermore, we may have to process personal data to meet with statutory requirements as provided by
                    Article 6.1(c) GDPR.</p>

                <h3>DO WE SHARE YOUR PERSONAL DATA?</h3>
                <p>
                    To provide the services, we share your personal data with service providers. These service providers
                    process your personal data on behalf of us. The processing by service providers is governed by a
                    so-called data processing agreement in which we have ensured that the service provider shall only
                    process the personal data on our instructions. Third parties are:<br>
                </p>
                <ul>
                    <li>external hosting providers, including cloud providers for the storage and management of data;</li>
                    <li>third parties that provide tools for analytical purposes (as described above);</li>
                    <li>other specific tasks that have been outsourced include IT support, email marketing, customer
                        service, moderation services (such as the chat function).</li>
                </ul>
                <p></p>
                <p>
                    We may also provide your personal data to external parties acting as a data controller. These parties
                    may include:
                </p>
                <ul>
                    <li>payment-service providers to (a) process payments; (b) prevent, detect, and investigate fraud or
                        other prohibited activities; (c) facilitate dispute resolution such as chargebacks or refunds; and
                        (d) for other purposes associated with the acceptance of credit or debit cards. We may share your
                        credit or debit card number with payment service providers or card networks to monitor card
                        transactions at participating merchants and track redemption activity for the purposes of providing
                        card-linked services.</li>
                    <li>buyers or other successors in the event of a merger, divestiture, restructuring, reorganization,
                        dissolution, or other sale or transfer of some or all the company\'s assets, whether as a going
                        concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information
                        held by us about the website\'s users is among the assets transferred.</li>
                    <li>Legal advisors or debt collection agencies.</li>
                    <li>Governmental authorities if we are obliged to do so on the basis applicable laws and / or
                        regulations or by means of a court order or a legal verdict. </li>
                    <li>Third parties for analytical and lead generation purposes. </li>
                </ul>
                <p></p>

                <h3>DO WE TRANSFER YOUR PERSONAL DATA OUTSIDE THE EUROPEAN ECONOMIC AREA?</h3>
                <p>Some of our service providers are also located outside the European Economic Area (EEA), the Republic of
                    Kosovo where the company\'s content servers are located, and the company\'s central database is
                    operated. To comply with EU data protection laws around international data transfer, WE only allow
                    service providers outside the EEA to process your personal data in accordance with a contract entered
                    into between us and the service provider, incorporating the European Commission’s Standard Contractual
                    Clauses, which ensure that adequate data protection arrangements are in place (Article 46.1(c) GDPR).
                    For more information on where and how the relevant document may be accessed or obtained, please contact
                    us.</p>

                <h3>WHAT ARE YOUR RIGHTS?</h3>
                <p>Under the GDPR, you have a number of rights with regard to your personal data and the processing thereof:
                </p>

                <h4>Right to access</h4>
                <p>you have the right to obtain confirmation as to whether or not personal data concerning you are being
                    processed, and, where that is the case, access to your personal data and additional information about
                    the processing of your personal data.</p>

                <h4>Right to rectification</h4>
                <p>you have the right to request the rectification of inaccurate personal data concerning you.</p>

                <h4>Right to be forgotten</h4>
                <p>you have to right to ask us to erase your personal data (right to be forgotten) for example if the
                    personal data are no longer necessary in relation to the purposes for which they were collected; you
                    withdraw consent on which the processing is based, and where there is no other legal ground for the
                    processing; or the personal data have been unlawfully processed.</p>

                <h4>Right to restriction</h4>
                <p>you have the right to obtain restriction of processing of your personal data, for example when you have
                    contested the accuracy of your personal data.</p>

                <h4>Data portability</h4>
                <p>you have the right to receive your personal data which you have provided to us in a structured, commonly
                    used and machine-readable format and have the right to transmit those data to another controller, where
                    the processing is based on your consent or on a contract.</p>

                <h4>Right to object</h4>
                <p>you have the right to object to processing of personal data which is based on our legitimate interests.
                    We shall no longer process the personal data unless we demonstrate compelling legitimate grounds for the
                    processing which override your interests, rights and freedoms or for the establishment, exercise or
                    defense of legal claims. </p>
                <p>where personal data are processed for direct marketing purposes, you always have the right to object to
                    processing of personal data for such marketing. In that case, we shall no longer process your personal
                    data for such purposes.</p>

                <h4>Withdraw consent</h4>
                <p>where the processing of your personal data is based on your consent, you have the right to withdraw your
                    consent at any time. The withdrawal of consent does not affect the lawfulness of processing based on
                    consent before its withdrawal.</p>

                <p>You may send your request to dpo@{{ instanceInfo?.domain }}. We will respond to your request without
                    undue delay
                    and in any event within one month of receipt of the request. That period may be extended by two further
                    months where necessary, taking into account the complexity and number of the requests. We will inform
                    you of any such extension within one month of receipt of the request, together with the reasons for the
                    delay. </p>
                <p>If we do not take action on your request, we will inform you without delay and at the latest within one
                    month of receipt of the request of the reasons for not taking action. In that case, you also have the
                    right to lodge a complaint with the supervisory authority and to seek a judicial remedy.</p>

                <h3>COMPLAINTS?</h3>
                <p>In case you suspect a breach of data protection legislation and the matter is not solved amicably between
                    us in negotiations, you also have the right to lodge a complaint with a supervisory authority in your
                    country. </p>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
    </div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Privacy',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
};
</script>
